import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Intranet = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [directory, setDirectory] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFiles = async () => {
      if (auth && auth.company) {
        //console.log('Fetching files for company:', auth.company);
        //local http://localhost:8888/
        try {
          const response = await axios.post('/backend/files.php', { empresa: auth.company });

          //console.log('Server response:', response.data);
          if (response.data.success) {
            console.log('Files fetched successfully:', response.data.files);
            setFiles(response.data.files);
            setDirectory(response.data.directory);
          } else {
            console.error('Failed to fetch files:', response.data.message);
            setDirectory(response.data.directory);
            alert('Failed to fetch files: ' + response.data.message);
          }
        } catch (error) {
          console.error('There was an error fetching the files!', error);
          if (error.response) {
            console.error('Server responded with an error:', error.response.data);
          } else if (error.request) {
            console.error('No response received from the server:', error.request);
          } else {
            console.error('Error setting up the request:', error.message);
          }
          alert('Error fetching files: ' + error.message);
        }
      } else {
        console.warn('No auth or company data available');
      }
    };

    fetchFiles();
  }, [auth]);

  const handleLogout = () => {
    setAuth(null);  // Borra la autenticación del contexto
    navigate('/login');  // Redirige al usuario a la página de inicio de sesión
  };

  return (
    <div className="container" style={{ marginTop: '150px', marginLeft:'57vh' }}>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Intranet</h2>
              <p className="text-center">Bienvenido, {auth.name}!</p>
              <div className="text-center"> {/* Centrar el botón */}
                <button onClick={handleLogout} className="btn btn-danger mb-3 btn-center">
                  Cerrar Sesión
                </button>
              </div>
              <h3 className="text-center">Archivos de {auth.company}</h3>
              {files.length > 0 ? (
                  files.map((file, index) => (
                    <ul key={index} className="list-group list-group-horizontal">
                      <li className="list-group-item flex-grow-1">
                        {file}
                      </li>
                      <li className="list-group-item">
                        <a 
                          href={`/backend/download.php?file=${encodeURIComponent(file)}&empresa=${encodeURIComponent(auth.company)}`} 
                          className="btn btn-primary"
                          download={file}
                        >
                          Descargar
                        </a>
                      </li>
                    </ul>
                  ))
                ) : (
                  <p className="text-center">No hay Archivos para descargar.</p>
                )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
